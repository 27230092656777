:root {
  /* Background */
  --bg-dark: #006530;
  --bg-dark-secondary: #006530;
  --bg-dark-third: #212529;
  --bg-white: #fff;
  --bg-white-secondary: #ffffffee;
  --bg-white-third: #ddd;
  --bg-success: #43a047;
  --bg-white-hover: rgba(255, 255, 255, 0.08);
  --bg-gray: #555;
  --bg-gray-secondary: #616161;
  --bg-login: #006530cc;

  /* Text */
  --white-text: #fff;
  --white-text-secondary: rgba(255, 255, 255, 0.7);
  --black-text: #000;
  --black-text-secondary: #212529;
  --gray-text: #555;
  --red-text: rgb(233, 43, 43);

  /* Border */
  --gray-border: #bbb;
  --gray-border-secondary: #c6c6c6;
  --gray-border-third: #888;
  --white-border: #fff;

  /* Botones */
  --btn-green: #00ae00;
  --btn-red: #ff0000;

  /* Scrollbar */
  --scroll-bar-track: #ccc;
  --scroll-bar-thumb: #888;
  --scroll-bar-thumb-hover: #555;

  /* Btn validaciones*/
  --bg-edit-btn: #98FB98;
  --bg-no-edit-btn: #C0C0C0;
  --bg-compliance-range-ok-btn: #98FB98;
  --bg-compliance-range-not-ok-btn: #f48760;
  --border-compliance-range-not-ok-btn-active: #f26b3b;
  --border-edit-btn-active: #5bcf5b;
}


/* ###### Estilos generales #### */

/*Oculta es scrollbar, pero se mantiene la funcionalidad*/
/* Para Chrome, Safari y Opera */
body::-webkit-scrollbar {
  display: none;
}

body {
  /* Para navegadores IE y EDGE */
  -ms-overflow-style: none;
  /* Para Firefox */
  scrollbar-width: none;
  background-color: var(--bg-dark);
  overflow: hidden;
}

.relativePosition {
  position: relative;
}

.fixedPosition {
  position: fixed;
}

.fullWidthContent {
  width: max-content;
  min-width: 100%;
}

.fondoCheck {
  background-color: var(--bg-gray-secondary) !important;
  padding: 4px !important;
  height: 33px;
}


.darkFound {
  color: var(--bg-gray);
}

.whiteLetter {
  color: var(--white-text) !important;
}

.alignRight {
  text-align: right;
}

.toRight {
  text-align: right !important;
}

.noPadding {
  padding: 0px !important;
}

.hidenPanel {
  display: none !important;
}

.displayFlex {
  display: flex;
}

.displayContents {
  display: contents;
}

.backgroundWhite {
  background-color: var(--bg-white);
}

.backgroundDarkSecondary {
  background-color: var(--bg-dark-secondary) !important;
}

.flexJustifyContentCenter {
  justify-content: center;
}

.flexAlignItemsCenter {
  align-items: center;
}

.flexAlignItemsBaseline {
  align-items: baseline;
}

.height100 {
  height: 100%;
}

.height100vh {
  height: 100vh !important;
}

.width100vw {
  width: 100vw;
}

.flexDirectionColumn {
  flex-direction: column;
}

.flexDirectionRow {
  flex-direction: row;
}

.flexJustifyContentSpaceBetween {
  justify-content: space-between;
}

.fullWidth {
  width: 100% !important;
}

.textAlignCenter {
  text-align: center !important;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

/* ##### Estilos específicos ##### */

.headerTablePage {
  height: 50px;
}

.headerTable {
  height: 27px;
}

.contentTable {
  height: calc(100% - 80px);
}

.botonDescargaPlantilla {
  background-color: var(--btn-green) !important;
}


.boxPlantillas {
  display: inline-block;
}

.botonDescargaReporte {
  margin: 0px !important;
  padding: 2px 10px 2px 10px !important;
  border-radius: 15px !important;
}

.levelPadreStyle0 {
  background-color: #666 !important;
}

.levelPadreStyle1 {
  background-color: #555 !important;
}

.levelPadreStyle2 {
  background-color: #444 !important;
}

.levelPadreStyle3 {
  background-color: #333 !important;
}

.levelPadreStyle4 {
  background-color: #222 !important;
}

.levelPadreStyle5 {
  background-color: #111111 !important;
}

.levelPadreStyle6 {
  background-color: #010101 !important;
}

.levelStyle0 {
  padding-left: 0px;
}

.levelStyle1 {
  padding-left: 10px;
}

.levelStyle2 {
  padding-left: 15px;
}

.levelStyle3 {
  padding-left: 20px;
}

.levelStyle4 {
  padding-left: 25px;
}

.levelStyle5 {
  padding-left: 30px;
}

.levelStyle6 {
  padding-left: 35px;
}

.paperContentPaquetes {
  overflow: auto;
}

.anchoNombrePaquete {
  max-width: 400px;
  min-width: 400px;
  width: 400px;
  display: inline-block;
  text-align: center;
}

.contenidoFila {
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  display: inline-block;
  text-align: center;
}

.contenidoFilaCodigoPaquete {
  max-width: 100px;
  min-width: 100px;
  width: 100px;
  display: inline-block;
  text-align: center;
}

.filaPadre {
  width: 100%;
  border-bottom: 1px;
  border-style: solid;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: var(--gray-border-third);
}

.botonEliminarComentario {
  position: absolute;
  top: 10px;
  right: 10px;
}

.littlePadding {
  padding: 3px !important;
}

.buttonComentarios {
  padding: 2px !important;
}

.menuButton .MuiPopover-paper {
  margin-top: 40px;
  width: 137px;
}


.heightSearchField {
  height: 32px !important;
}

.styleTabContent div:first-child {
  padding: 0px;
  margin: 0px;
}

.BottomMargin {
  margin-bottom: 10px !important
}

.BoxComentario {
  display: block;
  text-align: right;
}

.backgroundTextField {
  background-color: var(--bg-dark-third);
}

.ajusteBotonPaqueteNivel {
  padding: 10px;
  height: 100%;
}

.ajusteBotonPaqueteNivel {
  padding: 10px;
  height: 100%;
}


.NumericCell {
  max-width: 100px;
  color: var(--white-text);
}

.NumericCellPositive {
  max-width: 120px;
  color: var(--white-text);
  background-color: var(--btn-green);
  width: 120px;
  border-radius: 5px;
  text-align: right;
  padding-right: 5px;
  padding-left: 5px;
}

.NumericCellNegative {
  max-width: 120px;
  color: var(--white-text);
  background-color: var(--btn-red);
  width: 120px;
  border-radius: 5px;
  text-align: right;
  padding-right: 5px;
  padding-left: 5px;
}

.ContenidoTabs div {
  padding: 0px;

}

.TablaLE {
  height: calc(100vh - 320px);
  max-height: calc(100vh - 320px);
  padding: 0px;
}


.selectAll {
  user-select: all;
}

.overflowMessge {
  overflow-y: auto;
  min-width: 400px;
  max-height: 400px;
}



.bloqueBorder {
  border-radius: 5px;
  border-width: 1px !important;
  border-color: var(--gray-border-secondary) !important;
  border-style: solid;
  margin-bottom: 2px !important;
}

.paddingLoader {
  padding-left: 2px;
  padding-top: 3px;
}


.titleTab {
  font-size: 16px;
  margin-top: 4px !important;
  font-weight: bold;
}

.circleButton {
  border-radius: 15px !important;
  padding: 0px !important;
  margin: 0px !important;
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
}

.sizeDategRange {
  max-width: 600px;
}

.maxSizeModalDateRange .MuiDialog-paper {
  max-width: 750px !important;
}

.customDateRange {
  display: inline-block;
  width: 100%;
  min-width: 750px !important;
}

.bloqueDatePicker {
  width: 45%;
  float: right;
}

.bloqueDatePickerSeparator {
  width: 10%;
  float: right;
  text-align: center;
}

.paddingAlign {
  padding: 11px;
}

.panelContent {
  background: var(--bg-white-third);
  border-radius: 5px;
  padding: 5px;
}

.panelContentSuccess {
  background: var(--bg-success);
  border-radius: 5px;
  padding: 5px;
  color: var(--white-text);
  display: flex;
}

.labelLoading {
  position: absolute;
  margin-top: 100px;
  color: var(--white-text);
}

.horizontalScroll {
  overflow-x: auto;
  height: calc(100% - 56px);
}

.marginBottom {
  margin-bottom: 10px !important;
}

.errorBackground {
  background-color: var(--bg-gray);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 2px;
}

.menuHeight {
  padding-bottom: 150px;
}

.marginBottom {
  margin-bottom: 10px;
}

.heighFieldSelect div {
  padding: 2px !important;
  height: 25px !important;
}


.noPaddingField {
  margin-top: 2px;
}

.noPaddingField label {
  font-size: 11px;
  margin-top: -1px;
  padding: 2px !important;
}

.noPaddingField input {
  font-size: 11px;
  margin-top: -1px;
  padding: 2px !important;
}


.ktHroX {
  z-index: 10000;
}

.sizeProgressBar {
  border-radius: 20px;
}

.panelUploadFiles {
  border-radius: 20px;
}

.forceHeightInput .MuiOutlinedInput-root {
  height: auto !important;
  min-height: 39px !important;
  max-height: 400px !important;
}

.forceHeightInput div {
  padding: 0px !important;
  height: auto;
}

.marginLeftButton {
  margin-right: 10px !important;
}

.paddingSubMenu {
  padding-left: 30px !important;
}

.paddingSubSubMenu {
  padding-left: 60px !important;
}

.buttonAppBarSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}

@media (max-width: 600px) {
  .bloqueLogin {
    height: 100vh;
    padding: 20px;
    padding-top: 25%;
  }

  .bloqueLoginRegistro {
    height: 100vh;
    padding: 20px;
    padding-top: 1%;
  }
}

@media (min-width: 600px) {
  .bloqueLogin {
    height: 100vh;
    padding: 20px;
    padding-top: 25%;
  }

  .bloqueLoginRegistro {
    height: 100vh;
    padding: 20px;
    padding-top: 1%;
  }
}

.bakgroundBloqueBlanco {
  background-color: var(--bg-login) !important;
}

.logoCiamsa {
  width: 180px;
}

.bottonLogoMenu {
  display: flex;
  position: fixed;
  bottom: 0px;
  width: inherit;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-dark-secondary);
}

.backgroundPage {
  background-image: url(assets/bg_ciamsa_real.png);
  background-size: cover;
  background-attachment: fixed;
  height: auto;
  overflow-y: auto;
  padding: 5px !important;
}

.bigTextCode {
  font-size: 40px !important;
}

.backgroundPlain {
  background-color: var(--bg-white-secondary);
  height: 100%;
  padding: 5px;
  overflow: auto;
  border-radius: 5px;
}

.backgroundPlain::-webkit-scrollbar {
  display: none;
  border-radius: 5px !important;
}

.main-root-5 {
  padding: 5px !important;
}

.cellTableFontSize {
  font-size: 11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 6px !important;
}

.borderBloc {
  border: solid;
  border-width: 1px;
  border-color: var(--gray-border);
}

.borderBlocDown {
  border: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: var(--gray-border);
}

.cellTable {
  border: solid;
  border-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-color: var(--gray-border);
  color: var(--black-text-secondary);
  float: left;
  padding: 1px
}

.cellTableFirstCell {
  border: solid;
  border-width: 0px;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: var(--gray-border);
  float: left;
  padding: 1px;
  padding-top: 1px !important;
}

.cellTableFirstCell .MuiIconButton-sizeSmall {
  color: var(--black-text-secondary);
  border-width: 1px !important;
  border: solid !important;
  border-color: #ccc !important;
  width: 36px;
  margin-left: 2px;
  border-radius: 5px;
  background: #ddd !important;
  height: 25px;
}

.cellTableFirstCell .MuiIconButton-sizeSmall:hover {
  color: var(--black-text-secondary);
  border-color: #00653088 !important;
  background: #00653033 !important;
}

.cellTableFirstTopCell {
  border: solid;
  border-right-width: 1px;
  border-top-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-color: var(--gray-border);
}

.cellTableFirstTopLeftCell {
  border: solid;
  border-right-width: 1px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-color: var(--gray-border);
  color: var(--black-text-secondary);
}

.headerText {
  width: calc(100% - 25px);
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.headerIcon {
  width: 24px;
  float: left;
  height: 24px;
}

.headerIcon button {
  padding: 0px;
}

.headerIconMenu {
  width: 25px;
  float: right
}

.rowTable {
  width: 100%;
  display: "flex";
}

.fullTable {
  width: 100%;

}

.panel {
  color: var(--gray-text) !important;
  border: solid 1px;
  border-radius: 3px;
  border-color: var(--gray-border);
  width: 100%;
}

.centerContent {
  width: 100%;
  text-align: center;
  color: var(--black-text-secondary);
}

.fullWidthRight {
  width: 100%;
  text-align: right !important;
}

.paddingForce{
  padding: 7px !important;
}

.sizeNumberButton {
  width: 30px;
}

.textBarTable {
  font-size: 16px;
  float: right;
  padding-top: 8px;
}

.textPaginadorTable {
  float: right;
}

.textBarTableRight {
  float: left;
}

.widthSelect {
  padding: 5px;
}

.heightButton {
  height: 38px !important;
}

.paddingRow {
  padding: 5px;
}

#root {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  border-radius: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scroll-bar-track);
  border-radius: 5px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-thumb);
  border-radius: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar-thumb-hover);
  border-radius: 5px !important;
}

.error {
  color: var(--red-text);
  text-align: center;
}

/* Remover flechas del input de tipo number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.link-remove-default-styles {
  color: inherit !important;
  text-decoration: none !important;
}

.link-remove-default-styles:hover {
  color: inherit !important;
  text-decoration: none !important;
}

.dialogTitle {
  display: flex;
  align-items: center;
  background-color: var(--bg-dark-secondary);
}

.dialogTitle>.MuiIconButton-root:hover {
  background-color: var(--bg-white-hover);
}

.heightNewRegisterBtn {
  height: 55px;
}

.tableDashboard {
  max-height: 300px;
  min-width: 500px;
  overflow: auto;
  height: 100%;
}

.tableDashboardCellWidth45 {
  width: 45%;
}

.tableDashboardCellWidth25 {
  width: 25%;
}

.tableDashboardCellWidth30 {
  width: 30%;
}

.tableDashboard::-webkit-scrollbar:horizontal {
  display: none;
}

.tableDashboard::-webkit-scrollbar-thumb:horizontal {
  display: none;
}

.tableDashboardHead {
  height: 45px;
  font-weight: 500;
}

.tableDashboardHead .MuiTableCell-head {
  font-weight: 600;
}

.helpIcon {
  height: 40px !important;
  width: 40px !important;
}

.iconFormMixes {
  position: absolute !important;
  top: 0.1rem !important;
  right: 0.5rem !important;
  color: var(--white-text) !important;
}

.iconFormMixes:hover {
  background-color: var(--bg-white-hover) !important;
}

.mixesTable {
  min-width: 1900px;
}

.quoteFormCostButtonGroup {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 16px;
}

.newMixesBtn {
  height: 55px;
}

.noMargin {
  margin: 0 !important;
}

.commentsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 8px;
  gap: 1rem;
}

.approvalRequest {
  min-width: 500px;
  margin-top: 8px;
  padding: 16px 8px;
}

.quoteFormNewRegisterBtnContainer {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.quoteStepperContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
}

.quoteStepperHead {
  display: flex;
  align-items: center;
  height: 60px !important;
  padding-left: 16px !important;
  background-color: var(--bg-dark-secondary) !important;
  color: var(--white-text) !important;
}

.quoteStepperContent {
  height: 100%;
  width: 100%;
  padding: 5px;
  padding-top: 15px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.quoteStepperContent::-webkit-scrollbar {
  display: none;
}

.whiteLetter[aria-label="close"]:hover {
  background-color: var(--bg-white-hover) !important;
}

.quoteSummary {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
}

.quoteSummaryTableContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  margin-top: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  border-radius: 5px;
}

.quoteSummaryTable {
  min-width: 1200px !important;
}

.quoteSummaryTableRow {
  height: 40px;
}

.reportsConatiner {
  display: grid;
  padding: 8px;
  row-gap: 10px;
  column-gap: 10px;
}

.reportsCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

.reportsDownloadBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.reportsDownloadForm {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 8px;
}

.textfieldStartAdornmentContainer {
  display: block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.exportConfirmModalConatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--bg-white);
}

.exportConfirmModalForm {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.confirmModalContentText {
  color: var(--white-text-secondary) !important;
}

.confirmModalCancelBtn {
  border: none !important;
}

.confirmModalCancelBtn:hover {
  border: none !important;
}

.confirmModalConfirmBtn {
  border: none !important;
}

.confirmModalConfirmBtn:hover {
  border: none !important;
}

.cellTableBtnEditDate {
  width: 100%;
  outline: none;
  color: inherit;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 1.4375em;
  border: 1px solid var(--gray-border);
  font: inherit;
  background-color: transparent;
  border-radius: 3px;
  padding: 4px;
}

.dataOnePageDownloadForm {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 8px;
}

.mixesEditBtn {
  background-color: var(--bg-edit-btn) !important;
}

.mixesNotEditBtn {
  background-color: var(--bg-no-edit-btn) !important;
}

.mixesEditBtn.Mui-focused fieldset {
  border-color: var(--border-edit-btn-active) !important;
}

.mixesNotEditBtn.Mui-focused fieldset {
  border-color: var(--gray-border-third) !important;
}

.mixesEditBtnNotCompliance {
  background-color: var(--bg-compliance-range-not-ok-btn) !important;
  color: #fff !important;
}

.mixesEditBtnNotCompliance.Mui-focused fieldset {
  border-color: var(--border-compliance-range-not-ok-btn-active) !important;
}

.infoSegment{
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #444;
}

.celdaInfo{  
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #bbb;
}

.elementHeader{
  min-width: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  text-align: center !important;
}

.elementContent{
  min-width: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  text-align: center !important;
}

.elementContentLeft{
  min-width: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  text-align: left !important;
}

.elementContentRight{
  min-width: 35px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  text-align: right !important;
}

.cellNameMix{
  max-width: 130px;
  min-width: 130px;
}

.cellSeveralCenterMix{
  max-width: 100px;
  min-width: 100px;
  text-align: center !important;
}

.cellSeveralMix{
  max-width: 100px;
  min-width: 100px;
}

.cellSeveralMixRight{
  max-width: 120px;
  min-width: 120px;
  text-align: right !important;
}

.stylaTablePage{
  height: 100%;
  margin-top: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  border-radius: 5px;
}

.formMixes{
  margin-top: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  border-radius: 5px;
}

.elementColumn{
  min-width: 30px;
  max-width: 30px;
}

.littleSmallButton{
  height: 15px !important;
}

.cellTableLittelFontSize {
  font-size: 8px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right !important;
}

.littleInfoText {
  font-size: 9px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: right !important;
  color: #888;
}

.littleButtonText{
  font-size: 12px !important;
}

.cardItem{
  background-color: #00653033;
  border-radius: 5px;
  width: 100%;
  height: 55px;
  display: grid;
  padding: 5px;
}

.MuiSnackbar-anchorOriginBottomCenter{
  bottom: 40px !important;
}

.MuiSwitch-thumb{
  background-color: #ddd !important;
}

.Mui-checked .MuiSwitch-thumb{
  background-color: #006530 !important;
}

.checkboxField{
  background-color: #00000022;
  border-radius: 5px;
  height: 39px;
  padding-left: 10px;
  margin-left: 3px;
  margin-right: 3px;
}

.boxField{  
  margin-left: 3px;
  margin-right: 3px;
}

.spanInsideChack span{
  line-height: 1.5;
  overflow: hidden;
}

.titleExternalControl{
  background-color: #006530;
  padding: 10px;
  border-radius: 10px;
}

.titleExternalControlDenied{
  background-color: #ff8c00;
  padding: 10px;
  border-radius: 10px;
}

.whiteBackground{
  background-color: #ffffff;
  border-radius: 10px;
}

.developedLink{
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  right: 16px;
  background-color: #ffffffdd;
  padding: 5px;
  border-radius: 5px;
}

.titleText{
  font-size: 18px !important;
}

.marginTopStepper{
  padding-top: 5px;
}

.marginButtonText{
  margin-left: 5px;
  font-size: 16px !important;
}
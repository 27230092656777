/* Modificar las clases css de MUI*/
.MuiTableCell-root {
    padding: 3px !important;
}

.MuiTableCell-TablaLeCell {
    width: 200px !important;
    min-width: 150px !important;
    max-width: 250px !important;
}

.MuiTypography-body1 {
    font-size: 13px !important;
    font-weight: normal;
}

.MuiAutocomplete-root {
    width: 100% !important;
}

.MuiListItemText-primary {
    font-size: 13px !important;
    font-weight: normal;
}

.MuiAutocomplete-endAdornment .MuiSvgIcon-root {
    color: #000 !important;
    background-color: #bbb !important;
    border-radius: 4px;
    padding: 0px !important;
    margin: 0px !important;
    z-index: 1;
    width: 15px;
    height: 15px;
}

.materialui-daterange-picker-makeStyles-contrast-70 {
    color: #fff !important;
}

.MuiListItemIcon-root {
    min-width: 35px !important;
}

.MuiListItem-root {
    padding: 2px 10px 2px 10px !important;
}

.MuiDialog-paperWidthMd {
    max-width: 960px;
    width: 960px;
}

.MuiDialog-paperWidthSm {
    max-width: 600px;
    width: 600px;
}

.MuiDrawer-paperAnchorLeft {
    width: 240px !important;
}

/* Quitar el display grap del autocomplete */
.MuiAutocomplete-inputRoot {
    -webkit-box-flex-wrap: nowrap !important;
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis !important;
}
.height-row {
    height: 2.94rem !important;
}

.table-min-width {
    min-width: 700px;
}

.form-sectio-item-left {
    min-width: 300px;
}
body.loader-on{
    overflow: hidden !important;
  }
  #loader-box {
    display: none;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 20000 !important;
    background-color: rgba(47, 47, 47, 0.5);
  }
  #loader-box.on {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader,
  .loader:before,
  .loader:after {
    background: #fff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  .loader {
    color: #fff;
    text-indent: -9999em;
    position: relative;
    margin: 88px auto;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    position: absolute;
    top: 0;
    content: '';
  }
  .loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  
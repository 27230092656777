.tableContainer {
    display: grid !important;
    grid-area:
        "tableFilter"
        "headerTable"
        "contentTable"
    ;
    grid-auto-rows: min-content;
    overflow: auto;
}

.resizable {
    position: relative;
}

.resize {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 5px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 2;
    border-right: 2px solid transparent;
}

.headerTableContent {
    float: left;
    user-select: none;
}

.tableFirstColum {
    float: left;
    padding-right: 1rem;
}

.tableFilterInputContainer {
    min-width: 100% !important;
    float: left !important;
    padding-top: 6px !important;
}

.paginationLabelProgressContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto !important;
}

.paginationLabelProgressWrapper {
    float: right;
    color: #212529;
    width: 100%;
    text-align: right;
}

.paginationBtn {
    min-width: auto !important;
    width: 100%;
}